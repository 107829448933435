<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                :to="{ name: 'add-event' }"
                variant="primary"
              >
                <span class="text-nowrap">Add New Event</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="productsTable"
        :items="myProvider"
        :fields="eventsTableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        aria-busy="true"
        class="position-relative"
        responsive
        primary-key="id"
      >
        <template #cell(id)="data">
          # {{ data.item.id }}
        </template>

        <template #cell(title)="data">
          {{ data.item.title }}
        </template>

        <template #cell(exerpt)="data">
          <span v-if="data.item.exerpt">
            {{ data.item.exerpt.slice(0, 100) }}
          </span>
        </template>

        <template #cell(description)="data">
          <span v-if="data.item.description">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="sanitizeHtml(data.item.description.slice(0, 100))" />
          </span>
        </template>

        <template #cell(certifications)="data">
          <span> {{ data.item.date }} </span>
        </template>

        <template #cell(categories)="data">
          <span>{{ data.item.organization }}</span>
        </template>

        <template #cell(language)="data">
          <span
            v-if="data.item.language"
          >
            <b-badge variant="dark">{{ data.item.language.name }}</b-badge>
          </span>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item> -->

            <b-dropdown-item :to="{ name: 'edit-event', params: { id: data.item.id }}">
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteEvent(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <pagination
      :total-rows="totalRows"
      :per-page="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import sanitizeHtml from 'sanitize-html'
import events from '../events'
import eventsModule from '../eventsModule'
import store from '@/store'
import pagination from '@/common/components/Pagination.vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BBadge,
    pagination,
  },
  data() {
    return {
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      token: localStorage.getItem('access_token'),
    }
  },
  setup() {
    const EVENTS_DATA_MODULE = 'eventsModule'
    // const stor = useStore
    // Register module
    if (!store.hasModule(EVENTS_DATA_MODULE)) store.registerModule(EVENTS_DATA_MODULE, eventsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_DATA_MODULE)) store.unregisterModule(EVENTS_DATA_MODULE)
    })

    const { eventsTableColumns, productsList, productsDataList } = events()

    return {
      eventsTableColumns,
      productsList,
      productsDataList,
    }
  },
  methods: {
    sanitizeHtml,
    deleteEvent(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`delete/event/${id}`).then(res => {
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
            this.$refs.productsTable.refresh()
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    myProvider(ctx) {
      const promis = axios.get(`get/event?page=${ctx.currentPage}`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
      return promis.then(res => {
        const items = res.data.success.data
        this.totalRows = res.data.success.total
        return items
      // eslint-disable-next-line no-unused-vars
      }).finally(() => {
        this.loading = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => [])
    },
  },
}
</script>

<style>

</style>
